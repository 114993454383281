import styled from 'styled-components';
import colors from '../../constants/colors';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, addElement, addPages } from '../../constants/initialStore';
import { v4 as uuid } from 'uuid';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { ElementType } from '../../types/models/Elements';
import { albumFormats } from '../../constants/albumFormats';
import { ConvertMMToPixel } from '../../utils/convertCMToPixel';
import { useEffect, useState } from 'react';
import PriceInfoModal from '../../components/functionnalities/modals/PriceInfoModal';
import { PageRemover } from './AlbumPage';

export const pricePerPageMap = {
  SOUCAR30: '0.90',
  SOUCAR20: '0.7',
  SOUCAR14: '0.50',
  SOUA4PAY: '0.80',
  SOUA5PAY: '0.75',
  SOUA4POR: '0.80',
  SPICAR20: '0.70',
  RIGCAR30: '0.90',
  RIGA4PAY: '0.80',
  RIGCAR21: '0.70',
  RIGA4POR: '0.80',
  RIGA5PAY: '0.75',
  LAYFLATA4: '2.40',
  LAYFLAT30: '3.00',
};

const PageAdd: React.FC<{ index: number }> = ({ index }) => {
  const [priceInfoModalVisible, setPriceInfoModalVisible] = useState(false);
  const [albumFormat, setAlbumFormat] = useState('');
  const album = useSelector((state: RootState) => state.creation.present.album);
  const dispatch = useDispatch();

  const color = colors.green;

  const { t } = useTranslation(['common']);

  const AddPages = () => {
    if (!album) return;
    const { width, height } = albumFormats[album.format];

    const pageIds = [uuid(), uuid(), uuid(), uuid()];

    dispatch(addPages({ index, ids: pageIds }));
    pageIds.forEach((elmt, idx) => {
      dispatch(
        addElement({
          element: {
            type: ElementType.PHOTO,
            pageID: pageIds[idx],
            content: '',
            width: ConvertMMToPixel(width * 0.8),
            height: ConvertMMToPixel(height * 0.725),
            top: ConvertMMToPixel(height * 0.1375),
            left: ConvertMMToPixel(width * 0.1),
            zIndex: 0,
            editable: false,
            contentEditable: true,
            style: {
              brightness: 1,
              saturation: 100,
              opacity: 1,
              zoom: 100,
              rotation: 0,
              grayscale: false,
              sepia: false,
              mirrored: false,
              shadow: false,
              shape: 'rectangle',
              translateX: 0,
              translateY: 0,
              rotationFrame: 0,
            },
          },
        }),
      );
    });
  };

  useEffect(() => {
    if (!album) return;
    setAlbumFormat(album.format);
  }, [album]);

  return (
    <AddContainer
      title={
        t('album.pagePrice') +
        pricePerPageMap[album!.format as keyof typeof pricePerPageMap] +
        t('album.perPage')
      }
    >
      {priceInfoModalVisible && (
        <PriceInfoModal
          visible={priceInfoModalVisible}
          setVisible={setPriceInfoModalVisible}
          format={albumFormat}
          handleValidate={AddPages}
        />
      )}
      <Line style={{ minWidth: 'calc(50% - 20px)' }} />
      <AddButton onClick={() => setPriceInfoModalVisible(true)} $color={color}>
        <ReactSVG
          src="/svg/plus_white.svg"
          beforeInjection={(svg) => {
            svg.setAttribute(
              'style',
              'width: 12px; height: 12px; margin-top: 4px;',
            );
          }}
        />
      </AddButton>
      <Line />
      {album?.albumPages &&
        albumFormat !== '' &&
        album.albumPages.length > albumFormats[albumFormat].minPages && (
          <PageRemover index={index} />
        )}
    </AddContainer>
  );
};

const AddContainer = styled.div`
  grid-column: span 2;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 6px;
  margin-bottom: 6px;
`;

const Line = styled.div`
  height: 0.28px;
  flex: 1;
  background-color: #c2cfc5;
`;

const AddButton = styled.div<{ $color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: 8px;

  background-color: ${(props) => props.$color};
`;

export default PageAdd;
