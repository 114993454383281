import styled from 'styled-components';
import colors from '../../constants/colors';

export const HorizontalTabContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  top: 10px;
  left: 50%;
  flex-direction: row;
  width: 278px;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 4px;
  gap: 8px;
  z-index: 200;
  background-color: ${colors.gray200};
  border: 1px solid ${colors.gray100};
`;

export const HorizontalTab = styled.div<{ $active: boolean }>`
  display: flex;
  flex: 1;
  height: 36px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;

  background-color: ${(props) =>
    props.$active ? colors.white : 'transparent'};
  box-shadow: ${({ $active }) =>
    $active
      ? `0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)`
      : 'none'};
  color: ${(props) => (props.$active ? colors.black : colors.gray400)};
`;
