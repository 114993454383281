import { Element } from './Elements';
import { PhotoType } from './Photos';

export const CalendarTypes = {
  GRID: 'GRID',
  LINE: 'LINE',
  CORNER_SQUARE: 'CORNER_SQUARE',
  LONG: 'LONG',
  SQUARE: 'SQUARE',
  SEPARATOR_SPIRALE: "SEPARATOR_SPIRALE",
  COVER_SPIRALE: "COVER_SPIRALE",
  PAGES_UPPER_SPIRALE: "PAGES_UPPER_SPIRALE",
};

export type CalendarType = keyof typeof CalendarTypes;

export type Calendar = {
  id?: string;
  name?: string;
  ownerID?: string;
  format: string;
  type: CalendarType;
  monthStart: string;
  editorVersion?: string;
  calendarMonths: CalendarMonth[];
  calendarElements?: CalendarElement[];
};

export type CalendarMonth = {
  id?: string;
  month: string;
  backgroundColor: string;
  backgroundTheme?: string;

  calendarID?: string;
  calendar?: Calendar;

  calendarDays: CalendarDay[];
  calendarElements?: CalendarElement[];
};

export type CalendarCover = Omit<CalendarMonth, 'calendarDays'>;

export type CalendarDay = {
  id?: string;
  day: number;
  text?: string;
  photoID?: string;
  photo?: PhotoType;

  calendarMonthID?: string;
  calendarMonth?: CalendarMonth;
};

export type CalendarElement = Element;
