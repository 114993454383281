import { ReactSVG } from 'react-svg';
import { styled } from 'styled-components';
import colors from '../../constants/colors';
import Button, { buttonType } from '../general/Button';
import { useEffect, useLayoutEffect, useState } from 'react';
import ChangeNameModal from './modals/ChangeNameModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootState,
  changeAlbum,
  changeCalendar,
  changeCard,
  changeOther,
  closeModal,
  loadElements,
  openModal,
  selectAlbumPrice,
  selectCalendarPrice,
  selectCardPrice,
  selectOtherPrice,
} from '../../constants/initialStore';
import { LoginState, useAuth } from '../../hook/useAuth';
import {
  duplicateCard,
  endPersonnalisationCard,
  openModel,
  publishCard,
  saveNameCard,
} from '../../api/cards';
import { formats } from '../../constants/formats';
import UndoneModal from './modals/UndoneModal';
import LastVerificationModal from './modals/LastVerificationModal';
import useCreationType from '../../hook/useCreationType';
import { CardFromServer } from '../../types/models/Cards';
import { Calendar } from '../../types/models/Calendar';
import { Album } from '../../types/models/Album';
import { calendarFormats } from '../../constants/calendarFormats';
import {
  endPersonnalisationCalendar,
  saveNameCalendar,
} from '../../api/calendars';
import { albumFormats } from '../../constants/albumFormats';
import { endPersonnalisationAlbum, saveNameAlbum } from '../../api/album';
import { endPersonnalisationOther, saveNameOther } from '../../api/other';
import { otherFormats } from '../../constants/otherFormats';
import { getInitialProps, useTranslation } from 'react-i18next';
import styleConstants from '../../constants/styleConstants';
import useLittleScreen from '../../hook/useLittleScreen';
import { useAutoSaveAlbum } from '../../hook/useAutoSaveAlbum';
import { useAutoSaveCard } from '../../hook/useAutoSaveCard';
import { useAutoSaveCalendar } from '../../hook/useAutoSaveCalendar';
import { useAutoSaveOther } from '../../hook/useAutoSaveOther';
import CreationPreview from '../functionnalities/creationPreview/CreationPreview';
import useTheme from '../../hook/useTheme';
import { getOriginalPrice } from '../../api/products';
import Loader from '../general/Loader';
import { getCookieValue } from '../../pages/home/CookieHandler';
import Hotjar from '@hotjar/browser';

const PriceComponent = () => {
  const [price, setPrice] = useState<number>(0);
  const [initialPrice, setInitialPrice] = useState<number>(0);
  const [bonusPrice, setBonusPrice] = useState<number>(0);

  const card = useSelector(
    (state: RootState) => state.creation.present.card.value,
  );
  const calendar = useSelector(
    (state: RootState) => state.creation.present.calendar,
  );
  const album = useSelector((state: RootState) => state.creation.present.album);
  const other = useSelector((state: RootState) => state.creation.present.other);

  const currentType = useCreationType();

  useEffect(() => {
    const getInitialPrice = async () => {
      if (currentType === 'carte' && card) {
        const currentPrice = await getOriginalPrice(
          card.format,
          card.quantityAsked,
        );
        setInitialPrice(currentPrice.data);
      } else if (currentType === 'calendrier' && calendar) {
        const currentPrice = await getOriginalPrice(calendar.format);
        setInitialPrice(currentPrice.data);
      } else if (currentType === 'album' && album) {
        const currentPrice = await getOriginalPrice(album.format);
        setInitialPrice(currentPrice.data);
      } else if (currentType === 'autre' && other) {
        const currentPrice = await getOriginalPrice(other.format);
        setInitialPrice(currentPrice.data);
      }
    };
    const getBonusPrice = async () => {
      if (currentType === 'carte' && card) {
        const currentBonusPrice = selectCardPrice(card);
        setBonusPrice(currentBonusPrice);
      } else if (currentType === 'calendrier' && calendar) {
        const currentBonusPrice = selectCalendarPrice(calendar);
        setBonusPrice(currentBonusPrice);
      } else if (currentType === 'album' && album) {
        const currentBonusPrice = selectAlbumPrice(album);
        setBonusPrice(currentBonusPrice);
      } else if (currentType === 'autre' && other) {
        const currentBonusPrice = selectOtherPrice(other);
        setBonusPrice(currentBonusPrice);
      }
    };
    getInitialPrice();
    getBonusPrice();
  }, [card, calendar, album, other, currentType]);

  useEffect(() => {
    if (currentType === 'carte' && card.quantityAsked) {
      setPrice((initialPrice + bonusPrice) * card.quantityAsked);
    } else {
      setPrice(initialPrice + bonusPrice);
    }
  }, [initialPrice, bonusPrice]);

  return <Price>{Number(price).toFixed(2)}€</Price>;
};

const TopBar: React.FC = () => {
  const [name, setName] = useState<string>('');
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [formatTitle, setFormatTitle] = useState<string>('');
  const [undoneModalVisible, setUndoneModalVisible] = useState<boolean>(false);
  const [lastVerificationVisible, setLastVerificationVisible] =
    useState<boolean>(false);
  const [cardID, setCardID] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [triedToLeave, setTriedToLeave] = useState<boolean>(false);

  const [displayTick, setDisplayTick] = useState<boolean>(false);

  const [skuSearched, setSkuSearched] = useState('');

  const [showCreationPreview, setShowCreationPreview] =
    useState<boolean>(false);

  const { t, i18n } = useTranslation(['common']);
  const { t: tProduct } = useTranslation(['productsName']);

  const { initialLanguage } = getInitialProps();

  const changeNameModalVisible = useSelector(
    (state: RootState) => state.changeName.value,
  );

  const type = useCreationType();

  const card = useSelector(
    (state: RootState) => state.creation.present.card.value,
  );
  const calendar = useSelector(
    (state: RootState) => state.creation.present.calendar,
  );
  const album = useSelector((state: RootState) => state.creation.present.album);
  const autreCreation = useSelector(
    (state: RootState) => state.creation.present.other,
  );

  const saveAlbum = useAutoSaveAlbum();
  const saveCard = useAutoSaveCard();
  const saveCalendar = useAutoSaveCalendar();
  const saveOther = useAutoSaveOther();

  const dispatch = useDispatch();

  const { userInfo, asklogin } = useAuth();

  const littleScreen = useLittleScreen();
  const { color, logoFull } = useTheme();

  const handleLogin = async () => {
    await asklogin(false);
  };

  const handleChangeName = async (newName: string) => {
    setName(newName);
    switch (type) {
      case 'carte':
        await saveNameCard(cardID, newName);
        if (card) {
          dispatch(
            changeCard({
              ...card,
              name: newName,
            }),
          );
        }
        break;
      case 'calendrier':
        await saveNameCalendar(cardID, newName);
        if (calendar) {
          dispatch(
            changeCalendar({
              ...calendar,
              name: newName,
            }),
          );
        }
        break;
      case 'album':
        await saveNameAlbum(cardID, newName);
        if (album) {
          dispatch(
            changeAlbum({
              ...album,
              name: newName,
            }),
          );
        }
        break;
      case 'autre':
        await saveNameOther(cardID, newName);
        if (autreCreation) {
          dispatch(
            changeOther({
              ...autreCreation,
              name: newName,
            }),
          );
        }
    }
    dispatch(closeModal());
  };

  const handleSave = async () => {
    setDisplayTick(true);
    Hotjar.event('Sauvegarde de la création');
    switch (type) {
      case 'carte':
        await saveCard();
        break;
      case 'calendrier':
        await saveCalendar();
        break;
      case 'album':
        await saveAlbum();
        break;
      case 'autre':
        await saveOther();
    }
    setTimeout(() => {
      setDisplayTick(false);
    }, 3000);
  };

  const redirectToShopify = async (toCart: boolean, productToAdd?: string) => {
    setTriedToLeave(false);
    if (!toCart) {
      Hotjar.event('Fin de la personnalisation --> Abandon');
    }
    const loggedInFromPersoApp = localStorage.getItem('loggedInFromPersoApp');
    const accessToken = localStorage.getItem('accessToken');
    const currentCart = getCookieValue('cart_outil');
    const regex = new RegExp(`\\b${productToAdd}\\b`);
    // Voici où changer les URLs si besoin !
    const languageParam = new URLSearchParams(window.location.search).get(
      'lng',
    );

    const currentHost = window.location.hostname;

    const planetCardsUrl = import.meta.env.VITE_SHOPIFY_URL_PC;
    const planetPhotoUrl = import.meta.env.VITE_SHOPIFY_URL_PP;
    const staggingUrl = import.meta.env.VITE_SHOPIFY_URL_STAGGING;

    const pcHost = import.meta.env.VITE_URL_PERSO_PC;
    const ppHost = import.meta.env.VITE_URL_PERSO_PP;
    const staggingHost = import.meta.env.VITE_URL_PERSO_STAGGING ?? 'stagging';
    const localHost = import.meta.env.VITE_URL_PERSO_LOCAL ?? 'localhost';

    let beginUrl = '';
    if (currentHost == pcHost) {
      beginUrl = planetCardsUrl +
        (!!languageParam && languageParam !== 'fr'
          ? languageParam + '-' + languageParam + '/'
          : '');
    }
    else if (currentHost == ppHost) {
      beginUrl = planetPhotoUrl +
        (!!languageParam && languageParam !== 'fr'
          ? languageParam + '/'
          : '');
    }
    else if (currentHost == staggingHost) {
      beginUrl = staggingUrl;
    }
    else if (currentHost == localHost) {
      beginUrl = staggingUrl;
    }

    const redirectUrl =
      beginUrl +
      (toCart
        ? productToAdd !== null && productToAdd !== ''
          ? `cart/${currentCart ? currentCart + ',' : ''}${currentCart && regex.test(currentCart) ? '' : productToAdd + ':1'
          }?storefront=true`
          : 'cart'
        : '') +
      (loggedInFromPersoApp
        ? toCart && productToAdd
          ? `&token_persoapp=${accessToken}`
          : `?token_persoapp=${accessToken}`
        : '');

   
    localStorage.removeItem('loggedInFromPersoApp');

    if (!toCart) {
      history.back();
    } else {
      window.location.href = redirectUrl;
    }
  };

  const openSKU = async () => {
    const modelData = await openModel(skuSearched);
    const { ownerID: _1, CardFace: _2, ...rest } = modelData.data;
    dispatch(
      changeCard({
        ...formats[
        modelData.data.isOldModel ? rest.format + '_old' : rest.format
        ],
        ...rest,
        facesSizes: formats[
          modelData.data.isOldModel ? rest.format + '_old' : rest.format
        ].facesSizes.map((face, index) => {
          const faceFromServer = modelData.data.CardFace.find(
            (cardFace) => cardFace.faceID === index,
          );
          return {
            ...face,
            id: faceFromServer?.id,
            bgColor: faceFromServer?.backgroundColor,
            bgTheme: faceFromServer?.backgroundTheme,
          };
        }),
      }),
    );
    dispatch(
      loadElements(
        modelData.data.CardFace.flatMap((face) =>
          face.CardElement.map((element) => ({
            ...element,
            style: element.style ? JSON.parse(element.style) : undefined,
            face: face.faceID,
          })),
        ),
      ),
    );
    window.history.pushState(null, skuSearched, '/carte/' + modelData.data.id);
    // window.location.href = '/carte/' + modelData.data.id;
  };

  const openLastVerificationAndSave = () => {
    // if (userInfo?.state !== LoginState.LOGGED_IN) {
    //   asklogin(false);
    //   return;
    // }
    handleSave();
    setLastVerificationVisible(true);
  };

  const endPerso = async () => {
    await handleSave();
    Hotjar.event('Fin de la personnalisation --> Commande');
    const searchParams = new URLSearchParams(window.location.search);
    const fromCart = searchParams.get('fromCart') === 'true';
    setIsLoading(true);
    let res = null;
    let id = [''];
    switch (type) {
      case 'carte':
        // if (userInfo && userInfo.state === LoginState.LOGGED_IN) {
        res = await endPersonnalisationCard(cardID, initialLanguage, fromCart);
        id = res.data.split('/');
        setIsLoading(false);
        redirectToShopify(true, id[id.length - 1]);
        // } else {
        //   res = await endPersonnalisationCardPublic(
        //     cardID,
        //     initialLanguage,
        //     fromCart,
        //   );
        //   id = res.data.split('/');
        //   setIsLoading(false);
        //   redirectToShopify(true, id[id.length - 1]);
        // }
        break;
      case 'calendrier':
        res = await endPersonnalisationCalendar(
          cardID,
          initialLanguage,
          fromCart,
        );
        id = res.data.split('/');
        setIsLoading(false);
        redirectToShopify(true, id[id.length - 1]);
        break;
      case 'album':
        res = await endPersonnalisationAlbum(cardID, initialLanguage, fromCart);
        id = res.data.split('/');
        setIsLoading(false);
        redirectToShopify(true, id[id.length - 1]);
        break;
      case 'autre':
        res = await endPersonnalisationOther(cardID, initialLanguage, fromCart);
        id = res.data.split('/');
        setIsLoading(false);
        redirectToShopify(true, id[id.length - 1]);
        break;
    }
  };

  const getFormatTitle = () => {
    switch (type) {
      case 'carte':
        if (!card.format.includes('MAG')) {
          return card.format;
        }
        return tProduct('card.' + card.format);
      case 'calendrier':
        if (calendar && calendar.format) {
          return tProduct('calendar.' + calendar.format);
        }
        break;
      case 'album':
        if (album && album.format) {
          return tProduct('album.' + album.format);
        }
        break;
      case 'autre':
        if (autreCreation && autreCreation.format) {
          return tProduct('other.' + autreCreation.format);
        }
        break;
      default:
        break;
    }
    return '';
  };

  const publishProduct = async () => {
    const res = await publishCard(cardID);
    
    const splittedRes = res.data.split('/');
    
    console.log('URL EN DUR / OLD URL');
    window.location.replace(
      'https://admin.shopify.com/store/planet-cards-store/products/' +
      splittedRes[splittedRes.length - 1],
    );
  };

  useEffect(() => {
    switch (type) {
      case 'carte':
        setName(card.name);
        setWidth(card.width);
        setHeight(card.height);
        setFormatTitle(card.format);
        setCardID(card.id ?? '');
        break;
      case 'album':
        setName(
          i18n.exists('common:album.' + album?.name)
            ? t('album.' + album?.name)
            : album?.name ?? '',
        );
        setWidth(album ? albumFormats[album!.format].width ?? 0 : 0);
        setHeight(album ? albumFormats[album!.format].height ?? 0 : 0);
        setFormatTitle(album ? albumFormats[album!.format].name ?? '' : '');
        setCardID(album?.id ?? '');
        break;
      case 'calendrier':
        setName(
          i18n.exists('common:calendar.' + calendar?.name)
            ? t('calendar.' + calendar?.name)
            : calendar?.name ?? '',
        );
        setWidth(calendar ? calendarFormats[calendar!.format].width : 0);
        setHeight(calendar ? calendarFormats[calendar!.format].height : 0);
        setFormatTitle(calendar ? calendarFormats[calendar!.format].name : '');
        setCardID(calendar?.id ?? '');
        break;
      case 'autre':
        setName(autreCreation?.name ?? '');
        setWidth(autreCreation ? otherFormats[autreCreation!.format].width : 0);
        setHeight(
          autreCreation ? otherFormats[autreCreation!.format].height : 0,
        );
        setFormatTitle(
          autreCreation ? otherFormats[autreCreation!.format].name : '',
        );
        setCardID(autreCreation?.id ?? '');
        break;
    }
  }, [card, calendar, album, autreCreation, type]);

  return (
    <Container>
      <ChangeNameModal
        visible={changeNameModalVisible}
        setVisible={() => dispatch(closeModal())}
        initialName={name}
        handleValidate={handleChangeName}
      />
      <UndoneModal
        visible={undoneModalVisible}
        setVisible={setUndoneModalVisible}
        handleValidate={() => { }}
      />
      <LastVerificationModal
        visible={lastVerificationVisible}
        setVisible={setLastVerificationVisible}
        handleValidate={endPerso}
        isLoading={isLoading}
      />
      {showCreationPreview && (
        <CreationPreview setShowCreationPreview={setShowCreationPreview} />
      )}

      <CommandContainer>
        <ExitButton
          $type={buttonType['white-noborder']}
          onClick={() => redirectToShopify(false)}
        >
          <ReactSVG src="/svg/cross.svg" height={'24px'} width={'24px'} />
          {!littleScreen && t('general.exit')}
        </ExitButton>
        <Line />
        <InfoContainer>
          {!littleScreen && (
            <>
              <NameContainer>
                {name === '' ? t('general.creationName') : name}
                <EditButton
                  src="/svg/edit_pen.svg"
                  onClick={() => dispatch(openModal())}
                />
              </NameContainer>
              <SizeRefText>
                {width / 10} x {height / 10} cm | {getFormatTitle()}
              </SizeRefText>
            </>
          )}
        </InfoContainer>
        {userInfo?.state === LoginState.LOGGED_OUT ? (
          <Button
            $type={buttonType.themed}
            onClick={handleLogin}
            $themeColor={color}
          >
            <ReactSVG
              src="/svg/save.svg"
              beforeInjection={(svg) => {
                svg.setAttribute('style', 'width: 20px; height: 20px;');
              }}
            />
          </Button>
        ) : (
          <Button
            $type={buttonType.themed}
            onClick={handleSave}
            $themeColor={color}
          >
            <ReactSVG
              src={displayTick ? '/svg/tick_check.svg' : '/svg/save.svg'}
              beforeInjection={(svg) => {
                svg.setAttribute('style', 'width: 20px; height: 20px;');
              }}
            />
          </Button>
        )}
        {!littleScreen &&
          userInfo?.state === LoginState.LOGGED_IN &&
          userInfo.isAdmin && (
            <>
              <input
                value={skuSearched}
                onChange={(e) => setSkuSearched(e.currentTarget.value)}
              />
              <button onClick={openSKU}>Afficher</button>
            </>
          )}
      </CommandContainer>
      <LogoContainer>
        <img
          src={littleScreen ? '/logo.svg' : logoFull}
          style={{ height: '200%' }}
        />
      </LogoContainer>
      <PriceContainer>
        {!littleScreen && (
          <>
            <PriceComponent />

            {/* <Button
              $type={buttonType.white}
              onClick={() => setShowCreationPreview(!showCreationPreview)}
            >
              {t('general.preview')}
            </Button> */}
          </>
        )}
        <Line />
        <Button
          $type={buttonType.themed}
          onClick={endPerso}
          $themeColor={color}
        >
          {isLoading ? <Loader /> : t('general.order')}
        </Button>
        {userInfo?.state === LoginState.LOGGED_IN && userInfo.isAdmin && (
          <Button
            $type={buttonType.themed}
            onClick={publishProduct}
            $themeColor={color}
          >
            {isLoading ? <Loader /> : 'Créer'}
          </Button>
        )}
      </PriceContainer>
    </Container>
  );
};

const Container = styled.div`
  height: 72px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0 24px;
  box-sizing: border-box;

  top: 0;

  background-color: ${colors.white};
  border: solid 1px ${colors.gray200};

  @media (max-width: ${styleConstants.breakpointLg}) {
    height: 64px;
    padding: 12px 16px;
  }
`;

const CommandContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const ExitButton = styled(Button)`
  padding: 0;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;

  font-family: 'DM Sans';
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  text-decoration: underline;
  color: ${colors.black};
`;

const EditButton = styled(ReactSVG)`
  margin-left: 8px;

  cursor: pointer;
`;

const SizeRefText = styled.div`
  font-family: 'DM Sans';
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: ${colors.gray400};
`;

const LogoContainer = styled.div`
  height: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const Price = styled.div`
  font-family: 'DM Sans';
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: ${colors.black};
`;

// const DPI = styled(Price)`
//   width: 190px;
// `;

const Line = styled.div`
  width: 1px;
  height: 40px;

  background-color: ${colors.gray200};
`;

export default TopBar;
