import styled from "styled-components";

export const SpiraleContainer = styled.img<{
    $ratio: number;
    $top: number;
    $additionalCss?: string;
  }>`
    position: absolute;
    top: ${(props) => props.$top}%;
    left: 50%;
    width: 100%;
    transform: translateY(-50%) translateX(-50%);
    align-self: start;
    background-size: cover;
    cursor: pointer;
    z-index: 900;
    ${props => props.$additionalCss}
  `;